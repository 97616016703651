import * as React from "react";
import {useEffect, useState} from "react";
import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import banner from './images/banner-home.jpg';
import bannerVert from './images/banner-home-vert.jpg';
import badges from './images/badges.png';
import cta1 from './images/CTA/cta-1.png';
import cta2 from './images/CTA/cta-2.png';
import cta3 from './images/CTA/cta-3.png';
import cta4 from './images/CTA/cta-4.png';
import cta5 from './images/CTA/cta-5.png';
import trustPilotVert from './images/trust-pilot-vert.png';

import monthIcon from './images/6-month-icon.png';
import certIcon from './images/cert-icon.png';
import houseIcon from './images/house_icon.png';
import satAgreeIcon from './images/sat_agree_icon.png';

import Option from "@mui/joy/Option";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardOverflow, Divider,
    FormHelperText,
    FormLabel,
    Grid,
    Input,
    Link,
    Select,
    Sheet,
    Tooltip,
    Typography
} from "@mui/joy";
import {
    DoneAll,
    Email,
    Home,
    InfoOutlined,
    KeyboardArrowRight,
    KeyboardDoubleArrowRight,
    LocationOn, MobileFriendly,
    Phone,
    Star,
    TrendingFlat
} from "@mui/icons-material";
import {GetAddressFromPostcode, SubmitLeadForm} from "../Api";
import ReactGA from 'react-ga4';
import NavigationBar from "./NavigationBar";
import bannerIcons from './images/banner-icons.png';

interface AddressResponse {
    suggestions: Address[];
}

interface Address {
    address: string;
}


export default function Landing() {
    const {service, franchiseName} = useParams();
    const [selectedService, setSelectedService] = useState<string>('');
    const loadedData = useLoaderData() as LoadedData;
    const [franchise, setFranchise] = useState<Franchise>(loadedData?.franchise);
    const [lead, setLead] = useState<Lead>({
        address: "",
        eMail: "",
        houseType: "Detached",
        name: "",
        phone: "",
        postcode: ""
    });
    const [addressList, setAddressList] = useState<Address[]>([]);
    const [validPostcode, setValidPostcode] = useState<boolean>(true);
    const [debouncedPostcode, setDebouncedPostcode] = useState(lead.postcode);
    const [loadingBookingsPage, setLoadingsBookingsPage] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {

        if (service != null && service == 'gutter-clean' || service == 'window-clean') {
            if (service == 'gutter-clean') {
                setSelectedService('Gutter Clean')
            }
            if (service == 'window-clean') {
                setSelectedService('Window Clean')
            }
        } else {
            setSelectedService('Window Clean')
        }

    }, [])
    useEffect(() => {
        if (lead.postcode == "") {
            return;
        }
        const handler = setTimeout(() => {
            setDebouncedPostcode(lead.postcode);
            const getAddresses = async () => {
                let addresses: AddressResponse = await GetAddressFromPostcode(lead.postcode) as AddressResponse;
                setAddressList(addresses.suggestions)

                if (addresses.suggestions.length > 0) {
                    setValidPostcode(true)
                } else {
                    setValidPostcode(false)
                }

            };
            getAddresses();


        }, 750);


        return () => {
            clearTimeout(handler);
        };

    }, [lead.postcode]);


    const scrollToForm = () => {
        const formElement = document.getElementById('form-submit');
        if (formElement) {
            formElement.scrollIntoView({behavior: 'auto', block: 'center'});
        }
        if (lead.postcode) {
            sendInitialQuoteConversion()
            SubmitLeadForm(lead, selectedService, true);
        }
    };

    let reviews = new Map<string, string>([
        ["Sarah Jones", "We have our windows cleaned regularly by Sky High, we are pleased with the quality of the clean and reliability of the service. Today Mark cleaned our fascias and gutters, he did a fantastic job, worked really hard all morning, they are sparkling, thank you"],
        ["Nola Wolley", "Great service - you know in advance what day your windows will be cleaned with an option to change the day should you want. Excellent job done of cleaning windows. Would highly recommend. Also brilliant job done cleaning clogged gutters!"],
        ["Janice Saunders", "I use this company every year for gutter cleaning, fascias etc. Their work is carried out to an extremely high standard and always looks as good as new on completion; customer service is also good. I wouldn't hesitate to recommend this company"],
        ["Charlie Wemyss", "Mark at Sky High is always very professional, friendly and does a great job on our windows/gutters/fascia's. We would highly recommend Sky High to anyone."],
        ["Lydia Patel", "Would definitely recommend using Sky High. I am a property manager and use them on every site I manage. They are reliable, affordable, polite and efficient. Always willing to go the extra mile."],
        ["Andy T", "First time I've used Sky High and I would use them again. Good communication and price. Joe let me know when he would be coming round, turned up on time and did a great job. Can't ask for more."],
        ["Sally Griffin", "Mark is reliable, courteous and thorough. One can ask for little more except he also charges a fair price. I am very happy with the service I receive and will continue to use this company"],
        ["Samantha Lawn", "I trust these guys as they are reliable and always do a good job! We have a high 3 storey house and are v happy with the regular service"],
    ]);

    useEffect(() => {
        if (franchise) {
            document.title = 'Get A Quote | ' + franchise.location;
        }
    }, [franchise]);

    function saveLead() {
        setLoadingsBookingsPage(true)
        sendQuoteConversion()
        saveForm()
        navigate('/new-booking', {
            state: {
                lead, selectedService
            }
        });
    }

    function saveForm() {
        SubmitLeadForm(lead, selectedService, false);
    }

    function sendQuoteConversion() {
        let value = 65;
        if (selectedService.toLowerCase() == 'window clean') {
            value = 15
        }
        window.gtag('event', 'conversion', {
            'send_to': 'AW-16693850742/qGn8CIu989EZEPbkn5g-',
            'value': value,
            'currency': 'GBP'
        });

    }

    function sendInitialQuoteConversion() {
        let value = 65;
        if (selectedService.toLowerCase() == 'window clean') {
            value = 15
        }
        window.gtag('event', 'conversion', {
            'send_to': 'AW-16693850742/-OwOCMfi79EZEPbkn5g-',
            'value': value,
            'currency': 'GBP'
        });
    }


    return (
        <>
            <Box sx={(theme) => ({
                marginTop: '15px',
                display: 'flex',
                width: '1200px',
                height: '75px',
                marginLeft: 'auto',
                marginRight: 'auto',
                flexDirection: 'column',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                }
            })}>
                <NavigationBar selectedService={selectedService}/>


                <Box
                    sx={(theme) => ({
                        marginTop: '25px',
                        display: 'flex', width: '100%', minHeight: '500px',
                        backgroundImage: ` linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0.1) 100%), url(${banner})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: 'center',
                        [theme.breakpoints.down('md')]: {
                            minHeight: '150vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundImage: ` linear-gradient(180deg, rgba(0,0,0,0.4) 100%, rgba(255,255,255,0.6) 40%), url(${banner})`,
                            textAlign: 'center',
                            overflow: 'hidden',
                        },
                    })}
                >
                    <Box sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '25px',
                        width: '700px',
                        height: '100%',
                        justifyContent: 'center',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto',
                            marginLeft: '10px',
                            marginRight: '10px',

                        }
                    })}>

                        <Box sx={(theme) => ({
                            position: 'absolute', right: '15px', top: '100px',
                            [theme.breakpoints.up('md')]: {
                                display: 'none'
                            },
                        })}>
                            <img style={{"height": "50px"}} src={badges}
                                 alt="Sky High Window Badgest & Certificates"/>
                        </Box>


                        {franchise ? (
                            <Typography
                                sx={(theme) => ({
                                    height: 'fit-content',
                                    color: "white",
                                    textShadow: '2px 2px 2px rgba(0,0,0,0.1)',
                                    paddingTop: '15px',
                                    paddingBottom: '10px',
                                    [theme.breakpoints.down('md')]: {
                                        fontSize: '32px',
                                        textAlign: 'left',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        padding: '15px',
                                        width: '95%'
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: '90vw'
                                    }
                                })}
                                level={"h1"}>{`${selectedService}ing in ${franchise.location}`}{selectedService.toLowerCase() !== 'window clean' && ' from £65.00'}</Typography>

                        ) : (
                            <Typography
                                sx={(theme) => ({
                                    height: 'fit-content',
                                    color: "white",
                                    textShadow: '2px 2px 2px rgba(0,0,0,0.1)',
                                    paddingTop: '15px',
                                    paddingBottom: '10px',
                                    [theme.breakpoints.down('md')]: {
                                        fontSize: '32px',
                                        textAlign: 'left',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        padding: '15px',
                                        width: '95%'
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        width: '90vw'
                                    }

                                })}
                                level={"h1"}>{`${selectedService}ing in the United Kingdom`}{selectedService.toLowerCase() !== 'window clean' && ' from £65.00'}</Typography>
                        )}
                        <Box sx={(theme) => ({
                            marginTop: '30px', padding: '15px', background: 'white', borderRadius: '15px',
                            [theme.breakpoints.down('md')]: {
                                width: '90%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '0px'
                            },
                            [theme.breakpoints.down('sm')]: {
                                width: '85vw'
                            }
                        })}>

                            <Box sx={(theme) => ({
                                width: '100%', display: 'flex', [theme.breakpoints.down('md')]: {
                                    width: 'auto',
                                    height: '60px'
                                },
                            })}>
                                <Input variant="soft"
                                       value={lead.postcode}
                                       onChange={(event) => {
                                           setLead(prevLead => ({
                                               ...prevLead,
                                               postcode: event.target.value.toUpperCase(),
                                           }));
                                       }}
                                       sx={(theme) => ({
                                           paddingTop: '5px',
                                           paddingBottom: '5px',
                                           minWidth: '200px',
                                           maxWidth: '200px',
                                           "--Input-placeholderOpacity": 0.8,
                                           [theme.breakpoints.down('md')]: {maxWidth: '50%', minWidth: '50%'}
                                       })}
                                       startDecorator={<LocationOn/>}
                                       placeholder={"Postcode"}></Input>
                                <Select
                                    variant="soft"
                                    required
                                    value={lead.houseType}
                                    startDecorator={<Home/>}
                                    onChange={(e, newValue) => {
                                        setLead(prevLead => ({
                                            ...prevLead,
                                            houseType: newValue,
                                        }))
                                    }}
                                    sx={(theme) => ({
                                        minWidth: '200px',
                                        maxWidth: '200px',
                                        marginLeft: '5px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        "--Input-placeholderOpacity": 0.8,
                                        [theme.breakpoints.down('md')]: {maxWidth: '50%', minWidth: '50%'}
                                    })}
                                    defaultValue={"Detached"}
                                >
                                    <Option value={"Detached"}>Detached</Option>
                                    <Option value={"Semi Detached"}>Semi-Detached</Option>
                                    <Option value={"Terrace"}>Terrace</Option>
                                    <Option value={"Bungalow"}>Bungalow</Option>
                                    <Option value={"Ground Floor Apartment"}>Ground Floor Apartment</Option>
                                    <Option value={"1st Floor Apartment"}>1st Floor Apartment</Option>
                                    <Option value={"2nd Floor Apartment"}>2nd Floor Apartment</Option>
                                    <Option value={"3rd Floor Apartment"}>3rd Floor Apartment</Option>
                                </Select>

                            </Box>
                            <Box sx={{width: '100%', marginTop: '10px'}}>
                                <Input variant="soft"
                                       value={lead.phone}
                                       onChange={(event) => {
                                           setLead(prevLead => ({
                                               ...prevLead,
                                               phone: event.target.value,
                                           }))
                                       }}
                                       sx={(theme) => ({
                                           minWidth: '400px',
                                           maxWidth: '400px',
                                           paddingTop: '5px',
                                           paddingBottom: '5px',
                                           "--Input-placeholderOpacity": 0.8,
                                           [theme.breakpoints.down('md')]: {
                                               minWidth: '100%',
                                               maxWidth: '100%',
                                           }

                                       })}
                                       endDecorator={<Button size={"sm"} onClick={() => scrollToForm()}
                                                             sx={(theme) => ({
                                                                 paddingTop: '5px',
                                                                 paddingBottom: '5px',
                                                                 [theme.breakpoints.down('md')]: {
                                                                     fontSize: '12px',
                                                                     width: '150px'
                                                                 }

                                                             })} variant="solid" size="md"
                                                             endDecorator={<MobileFriendly/>}>Get
                                           Your Instant
                                           Quote</Button>}
                                       startDecorator={<Phone/>}
                                       placeholder={"Phone"}></Input>
                            </Box>

                        </Box>
                        <Box sx={(theme) => ({
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '50px',
                            [theme.breakpoints.up('md')]: {
                                display: 'none'
                            },
                            [theme.breakpoints.down('md')]: {
                                marginTop: '50px',
                            },
                            [theme.breakpoints.down('sm')]: {
                                marginTop: '25px',
                            },
                        })}>
                            <img style={{"height": "18vw"}} src={bannerIcons}
                                 alt="Sky High Window Badges & Certificates"/>
                        </Box>

                    </Box>
                    <Box sx={(theme) => ({
                        display: 'flex', width: '100%', justifyContent: 'flex-end', margin: '25px',
                        [theme.breakpoints.down('md')]: {
                            display: 'none'
                        },
                    })}>
                        <img style={{"height": "15vw", "maxHeight": "100px"}} src={badges}
                             alt="Sky High Window Badges & Certificates"/>

                    </Box>
                </Box>


                <Box sx={(theme) => ({
                    width: '100%', height: '150px', background: '#0B6BCB', [theme.breakpoints.down('md')]: {
                        display: 'none'
                    },
                })}>
                    <Box sx={(theme) => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '150px',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                        [theme.breakpoints.down('md')]: {
                            height: 'auto',
                            flexDirection: 'column',
                            marginTop: '10px',
                            marginBottom: '10px'
                        },
                    })}>
                        <Box sx={{
                            display: 'flex',
                            width: '20%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#001D83'
                        }}>
                            <img style={{height: '120px'}} src={cta1}
                                 alt="Sky High Certified Gutter Cleaning"/>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            width: '80%',
                            height: '100%',
                            background: '#F2F9FF',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>

                            <img style={{height: '120px'}} src={cta2}
                                 alt="Protect Against Insurance Claims"/>
                            <img style={{height: '120px'}} src={cta3}
                                 alt="Timestamp Certified for up to 6 months"/>
                            <img style={{height: '120px'}} src={cta4}
                                 alt="Full insuranced and trained professionals"/>
                            <img style={{height: '120px'}} src={cta5}
                                 alt="100% Satisfaction Guranteed or you don't pay"/>
                        </Box>

                    </Box>
                </Box>
                <Sheet id={"booking-form"} color="neutral" sx={(theme) => ({
                    display: 'flex',
                    width: '100%',
                    background: '#F2F9FF',
                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column'
                    },
                })}>
                    <Box sx={(theme) => ({
                        display: 'flex',
                        width: '50%',
                        flexDirection: 'column',
                        alignItems: 'normal',
                        height: '100%',
                        background: 'white',
                        padding: '40px',
                        paddingBottom: '0px',
                        boxSizing: 'border-box',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                            order: 3,
                            padding: '15px',
                            marginTop: '15px'
                        },
                    })}>
                        {selectedService == 'Window Clean' ? (
                            <>

                                <Typography level={"h2"} color={"primary"} fontWeight={750} sx={{marginBottom: '5px'}}>Sky
                                    High Window Cleaning </Typography>
                                <Typography level={"h2"} fontWeight={500} color={"neutral"}>From Just
                                    £15.00</Typography>
                            </>
                        ) : (
                            <>
                                <Typography level={"h2"} color={"primary"} fontWeight={750} sx={{marginBottom: '5px'}}>Sky
                                    High Certified Gutter Cleaning </Typography>
                                <Typography level={"h2"} fontWeight={500} color={"neutral"}>From Just
                                    £65.00</Typography>
                            </>

                        )}

                        <Typography level={"title-lg"} sx={{marginTop: '20px', marginBottom: '10px'}}>Did you know that
                            failing to maintain
                            your gutters could
                            invalidate
                            your home insurance?</Typography>
                        <Typography sx={{marginBottom: '10px'}}>Many homeowners are unaware of this critical requirement
                            in their insurance
                            policies. Insurance companies won't tell you directly, but you're liable to keep your
                            gutters clear.</Typography>
                        <Typography sx={{marginBottom: '10px'}}>Keep your home insurance valid and avoid costly claims
                            with our professional gutter
                            cleaning service. Our fully insured, trained experts provide a thorough clean, and you'll
                            receive a timestamped certificate proving your gutters are maintained for up to 6
                            months.</Typography>
                        <Typography sx={{marginBottom: '10px'}} fontWeight={750}>Plus, with our 100% satisfaction
                            guarantee, you don't pay a penny
                            unless you're
                            completely satisfied. Get started today from just £65!</Typography>

                        <Box>
                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<img style={{width: '30px', marginRight: '5px'}}
                                                             src={houseIcon}
                                                             alt="Icon Of A house"/>}
                                        sx={{mb: 2, mt: 3}}>
                                Protect Against Insurance Claims
                            </Typography>
                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<img style={{width: '30px', marginRight: '5px'}}
                                                             src={monthIcon}
                                                             alt="Icon Of A house"/>}
                                        sx={{mb: 2}}>
                                Timestamp Certified For Up To 6 Months
                            </Typography>
                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<img style={{width: '30px', marginRight: '5px'}}
                                                             src={satAgreeIcon}
                                                             alt="Icon Of A house"/>}
                                        sx={{mb: 2}}>
                                Full Insured and Trained Professionals
                            </Typography>
                            <Typography color={"primary"} fontWeight={750}
                                        startDecorator={<img style={{width: '30px', marginRight: '5px'}} src={certIcon}
                                                             alt="Icon Of A house"/>}
                                        sx={{mb: 2}}>
                                100% Satisfaction Guaranteed Or You don't Pay a Penny
                            </Typography>
                        </Box>
                    </Box>

                    <form class="form-width" id="form-submit" onSubmit={(event) => {
                        event.preventDefault();
                        saveLead()
                    }}>
                        <Box sx={(theme) => ({
                            display: 'flex', flexDirection: 'column', width: '550px', height: '100%', maxWidth: '550px',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                        })}>
                            <Typography color={"primary"} level={'h2'} sx={{textAlign: 'center', mt: 3, mb: 3}}>Get An
                                Instant Quote
                                Now</Typography>
                            <Box sx={(theme) => ({
                                width: '100%', margin: '10px',
                                [theme.breakpoints.down('md')]: {
                                    width: '90%',
                                },
                            })}>
                                <FormLabel>Full Name</FormLabel>
                                <Input required onChange={(event) => {
                                    setLead(prevLead => ({
                                        ...prevLead,
                                        name: event.target.value,
                                    }))
                                }}
                                       sx={{width: '100%', background: 'white'}}
                                       placeholder="Full Name"></Input>
                            </Box>
                            <Box sx={(theme) => ({
                                display: 'flex', flexDirection: 'row', width: '100%', margin: '10px',
                                [theme.breakpoints.down('md')]: {
                                    width: '90%',
                                    flexDirection: 'column'
                                },
                            })}>
                                <Box sx={{width: '100%', marginRight: '10px'}}>
                                    <FormLabel>E-Mail Address</FormLabel>
                                    <Input required startDecorator={<Email/>} onChange={(event) => {
                                        setLead(prevLead => ({
                                            ...prevLead,
                                            eMail: event.target.value,
                                        }));
                                    }} sx={{background: 'white'}} placeholder="Email"></Input>
                                </Box>
                                <Box sx={(theme) => ({
                                    width: '100%',
                                    [theme.breakpoints.down('md')]: {
                                        marginTop: '20px'
                                    }
                                })}>
                                    <FormLabel>Phone Number</FormLabel>

                                    <Input value={lead.phone} startDecorator={<Phone/>} onChange={(event) => {
                                        setLead(prevLead => ({
                                            ...prevLead,
                                            phone: event.target.value,
                                        }))
                                    }} sx={{background: 'white'}} placeholder="Phone Number"></Input>
                                </Box>
                            </Box>
                            <Box sx={(theme) => ({
                                width: '100%', margin: '10px',
                                [theme.breakpoints.down('md')]: {
                                    width: '90%',
                                },
                            })}>
                                <FormLabel>House Type</FormLabel>
                                <Select
                                    value={lead.houseType}
                                    required
                                    sx={{background: 'white'}}
                                    startDecorator={<Home/>}
                                    onChange={(e, newValue) => {
                                        setLead(prevLead => ({
                                            ...prevLead,
                                            houseType: newValue,
                                        }))
                                    }}
                                >
                                    <Option value={"Detached"}>Detached</Option>
                                    <Option value={"Semi Detached"}>Semi-Detached</Option>
                                    <Option value={"Terrace"}>Terrace</Option>
                                    <Option value={"Bungalow"}>Bungalow</Option>
                                    <Option value={"Ground Floor Apartment"}>Ground Floor Apartment</Option>
                                    <Option value={"1st Floor Apartment"}>1st Floor Apartment</Option>
                                    <Option value={"2nd Floor Apartment"}>2nd Floor Apartment</Option>
                                    <Option value={"3rd Floor Apartment"}>3rd Floor Apartment</Option>
                                </Select>
                            </Box>
                            <Box sx={(theme) => ({
                                width: '100%', margin: '10px',
                                [theme.breakpoints.down('md')]: {
                                    width: '90%',
                                },
                            })}>
                                <FormLabel>Postcode</FormLabel>
                                <Input required value={lead.postcode} inputProps={{style: {textTransform: "uppercase"}}}
                                       onChange={(event) => {
                                           setLead(prevLead => ({
                                               ...prevLead,
                                               postcode: event.target.value.toUpperCase()
                                           }));
                                       }} sx={{background: 'white'}} placeholder="Postcode"></Input>

                                {!validPostcode &&
                                    <FormHelperText>
                                        <Typography color="danger">Please enter a valid postcode!</Typography>
                                    </FormHelperText>
                                }

                            </Box>
                            <Tooltip
                                title={addressList?.length > 0 ? "" : "Please enter a valid postcode!"}
                                arrow placement="bottom" variant="solid"
                                color="danger">
                                <Box sx={(theme) => ({
                                    width: '100%', margin: '10px', maxWidth: '550px',
                                    [theme.breakpoints.down('md')]: {
                                        width: '90%',
                                    },
                                })}>
                                    <FormLabel>Address</FormLabel>
                                    <Select
                                        disabled={addressList?.length == 0}
                                        required
                                        slotProps={{
                                            listbox: {
                                                sx: {
                                                    maxWidth: '550px', minWidth: '550px',
                                                    "> li:nth-child(odd)": {
                                                        background: "rgba(240,244,248,0.6)",
                                                    },
                                                },
                                            },
                                        }}
                                        onChange={(e, newValue) => {
                                            setLead(prevLead => ({
                                                ...prevLead,
                                                address: newValue,
                                            }))
                                        }}
                                        placeholder="Select address..."
                                        sx={{background: 'white', maxWidth: '550px'}}
                                        startDecorator={<Home/>}
                                    >
                                        {addressList?.map((address: Address, index: number) => (
                                            <Option value={address.address}>{address.address}</Option>
                                        ))}

                                    </Select>
                                </Box>
                            </Tooltip>

                            <Box sx={(theme) => ({
                                width: '100%', margin: '10px',
                                [theme.breakpoints.down('md')]: {
                                    width: '90%',
                                },
                            })}>
                                {!loadingBookingsPage ? (
                                    <Button type="submit" sx={{width: '100%'}}
                                            endDecorator={<TrendingFlat/>}>Get
                                        Your Instant Quote</Button>

                                ) : (
                                    <Button loading sx={{width: '100%'}}>Get Your Instant Quote</Button>
                                )}
                            </Box>
                            <Box sx={(theme) => ({
                                marginTop: '25px',
                                alignItems: 'center',
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                [theme.breakpoints.down('md')]: {
                                    width: '70%',
                                    minWidth: '200px'
                                },
                            })}>
                                <img style={{width: '90%', minWidth: '250px'}}
                                     src={trustPilotVert}
                                     alt="Sky High Window Badges & Certificates"/>
                            </Box>
                        </Box>
                    </form>

                </Sheet>

                <Box sx={(theme) => ({
                    paddingBottom: '50px', width: '100%',
                    [theme.breakpoints.down('md')]: {
                        marginTop: '0px',
                    }
                })}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}>
                        <Typography level="h2" color="primary" mt={2}>What Our Customers Think</Typography>
                    </Box>
                    <Grid
                        container
                        spacing={1}
                        sx={(theme) => ({
                            flexGrow: 1,
                            marginTop: '25px',
                            justifyContent: 'center',
                            width: '100%',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        })}>
                        {Array.from(reviews).map(([key, value]) => (
                            <Grid sx={(theme) => ({
                                alignItems: 'center', justifyContent: 'center', width: '50%',
                                [theme.breakpoints.down('md')]: {
                                    width: '95%',
                                    mb: 5
                                }
                            })} size={4}
                                  key={key}>
                                <Card variant="outlined" sx={(theme) => ({
                                    height: '200px',
                                    background: 'white',
                                    [theme.breakpoints.down('md')]: {
                                        height: '100%',
                                    }
                                })}>
                                    <CardOverflow sx={(theme) => ({
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        padding: '25px',
                                        paddingBottom: '5px',
                                        [theme.breakpoints.down('md')]: {
                                            padding: '10px',
                                        }
                                    })}>
                                        <Typography level="title-md">{key}</Typography>
                                    </CardOverflow>
                                    <CardOverflow sx={(theme) => ({
                                        padding: '25px',
                                        paddingBottom: '0px',
                                        paddingTop: '0px',
                                        [theme.breakpoints.down('md')]: {
                                            padding: '10px',
                                            paddingBottom: '0px',
                                            paddingTop: '0px',
                                        }
                                    })}>
                                        <Typography sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "6",
                                            WebkitBoxOrient: "vertical",
                                        }}>{value}</Typography>
                                    </CardOverflow>
                                    <CardOverflow
                                        sx={(theme) => ({
                                            display: 'flex',
                                            marginTop: 'auto',
                                            flexDirection: 'row',
                                            padding: '25px',
                                            paddingTop: '0px',
                                            [theme.breakpoints.down('md')]: {
                                                padding: '10px',
                                                paddingTop: '0px',
                                            }
                                        })}>
                                        <Star sx={{color: 'gold'}}/>
                                        <Star sx={{color: 'gold'}}/>
                                        <Star sx={{color: 'gold'}}/>
                                        <Star sx={{color: 'gold'}}/>
                                        <Star sx={{color: 'gold'}}/>
                                    </CardOverflow>
                                </Card>
                            </Grid>
                        ))}

                    </Grid>
                </Box>
            </Box>
        </>
    )

}